import { $closest, $qs } from 'fxdom/es';
import { defaults, filter, flatten, go, identity, indexBy, map, pluck, reject, tap } from 'fxjs/es';
import { CateListS } from '../../../CateList/S/Function/module/CateListS.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { NewMakerProductStyleS } from '../../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { OMPProductListModalMuiF } from '../../../OMP/ProductList/Modal/F/Mui/module/OMPProductListModalMuiF.js';
import { ProductDetailTabsF } from '../../../ProductDetailTabs/F/Function/module/ProductDetailTabsF.js';
import { ProductDetailTabsConstantS } from '../../../ProductDetailTabs/S/Constant/module/ProductDetailTabsConstantS.js';
import { ProductDetailTabsTmplS } from '../../../ProductDetailTabs/S/Tmpl/module/ProductDetailTabsTmplS.js';
import { getReviewListByBox } from '../../../Review/List/F/fs.js';
import { makeReviewSummary } from '../../S/product.js';
import { changeBpByBpId } from '../change_bp.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { makeApiUrl } from '../../../Util/S/Function/util.js';

export const open_chooser = () => {
  const bp = box.sel('maker->product_color->_->base_product');
  if (bp.id == 2984) return $.alert('가져온 옷은 상품을 변경할 수 없습니다.');
  if ($qs('html.custom-detail-style-a')) return $.alert('해당 기능을 사용할 수 없습니다.');
  G.mp.maker.unselect_all();
  G.mp.maker.hide_cv_print_area();
  if (G.collabo_type == '' && !bp.is_use) return $.alert('비공개 상품은 사용 하실수 없습니다.');
  if (G.collabo_type == '' && !window.is_df) {
    return MuiF.openFrame(OMPProductListModalMuiF.frame);
  } else {
    return $.frame.open(
      {
        is_modal: !UtilF.isLegacyMobile(),
        frame_name: 'mp.product.list',
        animation: false,
        page_name: 'mp.product.list',
        el_class: 'product_detail_list',
        showing: function () {
          $.add_class($1('body'), 'body_scroll');
        },
        closing: function (el, new_bp) {
          if (!new_bp) return;
          $.don_loader_start();
          return go(changeBpByBpId(new_bp.id), $.don_loader_end);
        },
        closed: function (el, new_bp) {
          $.remove_class($1('body'), 'body_scroll');
        },
      },
      {
        page_name: 'mp.product.list',
        tabs: [
          {
            data_func: function () {
              const is_change_bp = !!$qs('.don_frame.product_detail_list');
              const product_list_get_url = makeApiUrl('/@api/product/list/:cate_list_id', {
                cate_list_id: bp.cate_list_id,
              });
              return Promise.all([
                go(
                  $.get(product_list_get_url, {
                    cate_item_id: bp.cate_item_id,
                    is_change_bp,
                  }),
                  is_change_bp
                    ? tap(
                        ({ bp_info }) =>
                          (bp_info.bps = reject(NewMakerProductStyleS.isCarvedPhoneCase, bp_info.bps)),
                      )
                    : identity,
                  (result) => {
                    window.box.set('product_list', result);
                    return result;
                  },
                ),
                go(
                  window.box.sel('cate_lists'),
                  is_change_bp
                    ? async (cate_lists) => {
                        if (box().not_vector_cate_lists) return box().not_vector_cate_lists;
                        const cate_item_ids = go(
                          cate_lists,
                          map(({ _: { cate_items } }) => pluck('id', cate_items)),
                          flatten,
                          (r) => r.join(','),
                        );

                        const data = await $.get(`/@api/not_vector_categories`, {
                          cate_item_ids,
                        });
                        const indexByData = indexBy((d) => d.cate_item_id, data);

                        return (box().not_vector_cate_lists = go(
                          cate_lists,
                          map((c) => {
                            const {
                              _: { cate_items },
                              _: rels,
                            } = c;
                            return {
                              ...c,
                              _: {
                                ...rels,
                                cate_items: filter(({ id }) => indexByData[id], cate_items),
                              },
                            };
                          }),
                          filter(({ _: { cate_items } }) => cate_items.length),
                        ));
                      }
                    : identity,
                ),
              ]);
            },
            tab_name: 'mp.product.list',
          },
        ],
      },
    );
  }
};

export const baseProductChangeInit = function () {
  return _p.go(void 0, async function () {
    G.mp.maker.reset_box_data();
    const new_bp = box.sel('maker->product_color->_->base_product');
    const product_info_detail_el = $1('.product_info_detail');
    const tab_el = $closest('.don_tab', product_info_detail_el);
    if (new_bp.id === $.attr(product_info_detail_el, 'bp_id')) return;
    const is_mobile = UtilF.isLegacyMobile();
    const base_product = box().maker.product_color._.base_product;
    const print_guide = base_product['print_guide' + (is_mobile ? '_m' : '_pc') + G._en];
    if ($1('html.design_collection_maker')) {
      product_info_detail_el.outerHTML = ProductDetailTabsTmplS.productInfoDetail(
        {
          bp_id: base_product.id,
          thumbnails: base_product._.base_product_detail['thumbnails' + G._en],
          tabs: [
            {
              name: ProductDetailTabsConstantS.BP_DETAIL_TAB.name,
              title: ProductDetailTabsConstantS.BP_DETAIL_TAB.title(),
              template_str: ProductDetailTabsConstantS.BP_DETAIL_TAB.template({
                id: base_product.id,
                size_info_url: base_product.size_info_url,
                cate_list_id: base_product.cate_list_id,
                cate_item_id: base_product.cate_item_id,
                size_compare: base_product._.base_product_detail.size_compare,
                brand_logo: base_product.brand_logo,
                brand_name: base_product['brand_name' + G._en],
                brand_description: base_product['brand_description' + G._en],
                product_info: base_product['product_info' + G._en],
                guide: is_mobile ? base_product.guide_m : base_product.guide_pc,
                size_table: base_product['size_table' + G._en],
                is_mobile,
              }),
              is_hidden: false,
            },
            {
              name: ProductDetailTabsConstantS.MANUFACTURE_TAB.name,
              title: ProductDetailTabsConstantS.MANUFACTURE_TAB.title(),
              template_str: ProductDetailTabsConstantS.MANUFACTURE_TAB.template({
                print_guide,
                id: base_product.id,
              }),
              is_hidden: G.collabo_type !== '' && !print_guide,
            },
          ],
        },
        is_mobile,
      );
    } else if (G.collabo_type === '') {
      if (!$1('html.setting-products')) G.mp.maker.extend_state_bp(new_bp.id);
      window.box.set('review_list->query', {
        bp_id: new_bp.id,
        is_all: false,
        is_total: false,
        is_phonecase: CateListS.isPhoneCaseByCateItemId(new_bp.cate_item_id) || void 0,
        is_frame: CateListS.isFrame(new_bp.cate_item_id) || void 0,
        is_sticker: CateListS.isSticker(new_bp.cate_item_id) || void 0,
        is_handy_sticker: CateListS.isHandySticker(new_bp.cate_item_id) || void 0,
        is_tattoo: CateListS.isTaToo(new_bp.cate_item_id) || void 0,
        is_key_cap: CateListS.isKeyCap(new_bp.cate_item_id) || void 0,
        collabo_type: ['creator', ''].includes(G.collabo_type) ? 'mp' : undefined,
      });

      const don_review_list = $.find1(product_info_detail_el, '.omp-review__contents.infi_container');
      don_review_list.dataset.type = 'is_photo';
      const base_product = box().maker.product_color._.base_product;
      const is_mobile = UtilF.isLegacyMobile();
      const query = window.box.sel('review_list->query');
      const review_list = await $.get(
        `/${TT.lang}/@api/review/list`,
        defaults(
          {
            limit: 36,
            is_list: true,
          },
          query,
        ),
      );

      window.box.extend2('review_list', review_list);
      /*
       * 상품 이름 아래 별점 및 리뷰개수 한번 더 덮는다.
       * */
      $1('.review_summary').outerHTML = makeReviewSummary({ review_total: review_list.review_total });
      product_info_detail_el.outerHTML = await ProductDetailTabsTmplS.productInfoDetail(
        {
          bp_id: base_product.id,
          thumbnails: base_product._.base_product_detail['thumbnails' + G._en],
          tabs: [
            G.collabo_type === ''
              ? {
                  name: ProductDetailTabsConstantS.STORY_TAB.name,
                  title: ProductDetailTabsConstantS.STORY_TAB.title(),
                  template_str: ProductDetailTabsConstantS.STORY_TAB.template({
                    design_collection: base_product._.design_collection,
                    is_mobile,
                    product_type: ProductDetailTabsConstantS.STORY_TAB.product_type(base_product),
                  }),
                  is_hidden: !base_product._.design_collection?.id,
                }
              : null,
            {
              name: ProductDetailTabsConstantS.BP_DETAIL_TAB.name,
              title: ProductDetailTabsConstantS.BP_DETAIL_TAB.title(),
              template_str: ProductDetailTabsConstantS.BP_DETAIL_TAB.template({
                id: base_product.id,
                size_info_url: base_product.size_info_url,
                cate_list_id: base_product.cate_list_id,
                cate_item_id: base_product.cate_item_id,
                size_compare: base_product._.base_product_detail.size_compare,
                brand_logo: base_product.brand_logo,
                brand_name: base_product['brand_name' + G._en],
                brand_description: base_product['brand_description' + G._en],
                product_info: base_product['product_info' + G._en],
                guide: is_mobile ? base_product.guide_m : base_product.guide_pc,
                size_table: base_product['size_table' + G._en],
                is_mobile,
              }),
              is_hidden: false,
            },
            {
              name: ProductDetailTabsConstantS.MANUFACTURE_TAB.name,
              title: ProductDetailTabsConstantS.MANUFACTURE_TAB.title(),
              template_str: ProductDetailTabsConstantS.MANUFACTURE_TAB.template({
                print_guide,
                id: base_product.id,
              }),
              is_hidden: G.collabo_type !== '' && !print_guide,
            },
            {
              name: ProductDetailTabsConstantS.REVIEW_TAB.name,
              title: ProductDetailTabsConstantS.REVIEW_TAB.title(review_list?.review_total?.count),
              template_str: ProductDetailTabsConstantS.REVIEW_TAB.template({
                is_mobile,
                reviews: [],
                review_total: review_list.review_total,
                query: box.sel('review_list->query'),
              }),
              is_hidden: parseInt(review_list.review_total.count) === 0,
            },
          ],
        },
        is_mobile,
      );
      _p.go($1('.omp-review__contents.infi_container'), $.after(don_review_list), $.remove);
    } else {
      if (!$1('html.setting-products')) G.mp.maker.extend_state_bp(new_bp.id);
      window.box.set('review_list->query', {
        bp_id: new_bp.id,
        is_all: true,
        is_phonecase: CateListS.isPhoneCaseByCateItemId(new_bp.cate_item_id) || void 0,
        is_frame: CateListS.isFrame(new_bp.cate_item_id) || void 0,
        is_sticker: CateListS.isSticker(new_bp.cate_item_id) || void 0,
        is_handy_sticker: CateListS.isHandySticker(new_bp.cate_item_id) || void 0,
        is_tattoo: CateListS.isTaToo(new_bp.cate_item_id) || void 0,
        is_key_cap: CateListS.isKeyCap(new_bp.cate_item_id) || void 0,
        collabo_type: ['creator', ''].includes(G.collabo_type) ? 'mp' : undefined,
      });

      const don_review_list = $.attr($.find1(product_info_detail_el, '.review_list.infi_container'), {
        photo_review: false,
      });
      const base_product = box().maker.product_color._.base_product;
      const is_mobile = UtilF.isLegacyMobile();
      const review_list = await getReviewListByBox(don_review_list);
      product_info_detail_el.outerHTML = await ProductDetailTabsTmplS.productInfoDetail(
        {
          bp_id: base_product.id,
          thumbnails: base_product._.base_product_detail['thumbnails' + G._en],
          tabs: [
            G.collabo_type === ''
              ? {
                  name: ProductDetailTabsConstantS.STORY_TAB.name,
                  title: ProductDetailTabsConstantS.STORY_TAB.title(),
                  template_str: ProductDetailTabsConstantS.STORY_TAB.template({
                    design_collection: base_product._.design_collection,
                    is_mobile,
                    product_type: ProductDetailTabsConstantS.STORY_TAB.product_type(base_product),
                  }),
                  is_hidden: !base_product._.design_collection?.id,
                }
              : null,
            {
              name: ProductDetailTabsConstantS.BP_DETAIL_TAB.name,
              title: ProductDetailTabsConstantS.BP_DETAIL_TAB.title(),
              template_str: ProductDetailTabsConstantS.BP_DETAIL_TAB.template({
                id: base_product.id,
                size_info_url: base_product.size_info_url,
                cate_list_id: base_product.cate_list_id,
                cate_item_id: base_product.cate_item_id,
                size_compare: base_product._.base_product_detail.size_compare,
                brand_logo: base_product.brand_logo,
                brand_name: base_product['brand_name' + G._en],
                brand_description: base_product['brand_description' + G._en],
                product_info: base_product['product_info' + G._en],
                guide: is_mobile ? base_product.guide_m : base_product.guide_pc,
                size_table: base_product['size_table' + G._en],
                is_mobile,
              }),
              is_hidden: false,
            },
            {
              name: ProductDetailTabsConstantS.MANUFACTURE_TAB.name,
              title: ProductDetailTabsConstantS.MANUFACTURE_TAB.title(),
              template_str: ProductDetailTabsConstantS.MANUFACTURE_TAB.template({
                print_guide,
                id: base_product.id,
              }),
              is_hidden: G.collabo_type !== '' && !print_guide,
            },
            {
              name: ProductDetailTabsConstantS.REVIEW_TAB.name,
              title: ProductDetailTabsConstantS.REVIEW_TAB.title(review_list?.review_total?.count),
              template_str: ProductDetailTabsConstantS.REVIEW_TAB.template({
                is_mobile,
                reviews: [],
                review_total: review_list.review_total,
                query: box.sel('review_list->query'),
              }),
              is_hidden: parseInt(review_list.review_total.count) === 0,
            },
          ],
        },
        is_mobile,
      );
      _p.go($1('.review_list.infi_container'), $.after(don_review_list), $.remove);
    }
    await ProductDetailTabsF.init(tab_el, true);
  });
};
